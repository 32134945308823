@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply outline-none !important;
  }
  html {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
               -webkit-tap-highlight-color: transparent;
  }
  body {
    @apply font-sans text-[14px] bg-black;
  }
  input, textarea {
    @apply placeholder:text-[rgba(255,255,255,0.5)];
  }
  button {
    @apply select-none;
  }
}

/* Input */
.input {
  @apply block w-full py-[7px] px-[10px] border border-[3px] border-[#5e5b5b] leading-[18px] font-oswald text-white bg-black;
}
.input.error {
  @apply border-[#ff0000];
}

/* Textarea */
.textarea {
  @apply block w-full py-[7px] px-[10px] border border-[3px] border-[#5e5b5b] leading-[18px] font-oswald text-white bg-black resize-none;
}
.textarea.error {
  @apply border-[#ff0000];
}

/* Btn */
.btn {
  @apply inline-block py-[7px] px-[10px] border border-[3px] border-[#5e5b5b] leading-[18px] font-oswald text-white transition-[background] duration-150 bg-black hover:bg-[#3a3a3a] active:bg-[#5e5b5b] disabled:opacity-50 disabled:cursor-default;
}

/* Content */
.content h1 {
  @apply text-[16px] font-bold text-white;
}
.content p {
  @apply mt-[20px] leading-[20px] text-white;
}
.content p:first-child {
  @apply mt-0;
}
.content ul {
  @apply pl-[13px] mt-[20px] leading-[20px] list-disc text-white;
}
.content ul:first-child {
  @apply mt-0;
}
.content ol {
  @apply pl-[23px] mt-[20px] leading-[20px] list-decimal text-white;
}
.content ol:first-child {
  @apply mt-0;
}

/* Fade */
.fade-enter {
  @apply opacity-0;
}
.fade-enter-active {
  @apply opacity-100 transition-[opacity] duration-150;
}
.fade-exit {
  @apply opacity-100;
}
.fade-exit-active {
  @apply opacity-0 transition-[opacity] duration-150;
}
